<template>
	<div class="view pa24">
		<el-button class="mr10" type="primary" @click="openAdd()">添加产品
		</el-button>
		<commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
			@handleCurrentChange="handleCurrentChange" :currentPage="pageNum" :total="total">
			<template v-slot:table>
				<!--<el-table-column align="center" prop="taskId"  label="序号"/>-->
				<el-table-column prop="name" align="center" label="产品名称(对内)" />
				<el-table-column prop="className" align="center" label="产品类别(对外)" />
				<el-table-column prop="spec" align="center" label="包装规格" />
				<el-table-column prop="ratio" align="center" label="装箱规格" />
				<el-table-column prop="createTime" align="center" width="155px" label="创建时间" />
				<el-table-column align="center" label="操作" width="200">
					<template slot-scope="scope">
						<el-button type="text" @click="edit(scope.row, true);">编辑
						</el-button>
						<customPopconfirm confirm-button-text="确定" k cancel-button-text="取消" @confirm="del(scope.row.id)"
							icon="el-icon-info" icon-color="red" title="确定要删除吗？">
							<el-button slot="reference" class="ml10" style="color: #fd634e" type="text">删除
							</el-button>
						</customPopconfirm>
					</template>
				</el-table-column>
			</template>
		</commonTable>
		<!--添加、编辑任务弹窗-->
		<el-dialog :title="diagTitle" :visible.sync="centerDialogVisible" @open="openDialog" @close="close"
			:close-on-click-modal="false" :modal-append-to-body="false" :destroy-on-close="true" width="50%" center>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
				<el-form-item label="产品名称" prop="name">
					<el-input v-model="ruleForm.name"></el-input>
				</el-form-item>
				<el-form-item label="产品类别" prop="classId">
					<el-select v-model="ruleForm.classId" placeholder="请选择产品类别" style="width: 350px">
						<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>

				<!-- <el-form-item label="条码">
					<el-input v-model="ruleForm.code"></el-input>
				</el-form-item> -->
        <el-form-item label="装箱规格" prop="ratio">
          <el-input-number :min="1" v-model="ruleForm.ratio"></el-input-number>
        </el-form-item>
        <p style="color: red;margin-left: 20px;margin-bottom: 20px">注：修改装箱规格后，客户端需要重新选择对应产品，使客户端装箱规格进行同步更新</p>
				<el-form-item label="包装规格">
					<el-input v-model="ruleForm.spec"></el-input>
				</el-form-item>

			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="determine">确 定</el-button>
			</span>
		</el-dialog>

	</div>

</template>

<script>
	import {
		pageClassList
	} from '@/api/customPageClass.js'
	import commonTable from "@/components/common/commonTable";
	import {
		mapState
	} from 'vuex';
	import customPopconfirm from "@/components/common/customPopconfirm";
	import {
		selectPrintProduce,
		addPrintProduce,
		delPrintProduce
	} from "@/api/printProduce";

	let ac;
	export default {
		name: 'printJob',
		data() {
			return {
				pageData: {},
				taskHandleData: [],
				taskInfoDialogVisible: false,
				taskDownVisible: false,
				TipsInfo: false,
				isPostDialogVisible: true,
				diagTitle: '添加产品信息',
				proValue: '',
				proOption: [],
				pbOption: [],
				xbOption: [],
				deviceList: [],
				//
				tableData: [],
				pageNum: 1, // 当前页
				pageSize: 10, //显示条数
				loading: false, //表格加载
				total: 0, //总条数
				taskList: [],
				centerDialogVisible: false,
				bEdit: false,
				editUpdate: true,
				isUpdate: true,
				fileList: [],
				userInfo: this.$store.state.loginRoot.userInfo,

				ruleForm: {
					id: '',
					name: '',
					introduce: '',
					spec: '',
					// code: '',
					ratio: 1,
					classId: ''
				},
				rules: {
					name: [{
						required: true,
						message: '请填写产品名称',
						trigger: 'blur'
					}],
					classId: [{
						required: true,
						message: '请选择产品类别',
						trigger: 'change'
					}],
          ratio:[
            {
              required: true,
              message: '请填写装箱规格',
              trigger: 'blur'
            }
          ]

				},

				devOption: [],
				downForm: {
					taskId: '',
					deviceId: '', // 设备id
					postNum: '',
					postStatus: '',
					remarks: '',
				},
				downRules: {},
				options: []
			};
		},
		components: {
			commonTable,
			customPopconfirm,
		},
		filters: {

		},
		computed: {
			...mapState(['routerArr']),
		},
		watch: {},
		created() {},
		mounted() {
			this.getPageClassList()
			this.queryPage();
			// this.getProductBatch();
			// this.getBoxBatch();
		},
		beforeDestroy() {

		},
		methods: {
			getPageClassList() {
				pageClassList().then(res => {
					console.log(res)
					if (res.code == 200 && res.data && res.data.list.length > 0) {
						this.options = res.data.list
					}
				})
			},
			getLotNumber() {
				getLotNumber().then(res => {
					this.ruleForm.lotNumber = res.data
				})
			},
			noVisible() {
				this.TipsInfo = false;
				this.isPostDialogVisible = true;
				this.taskDownVisible = false;
				this.downForm = {
					taskId: '',
					deviceId: '', // 设备id
					remarks: '',
				}
			},
			isVisible() {
				if (this.isPostDialogVisible) {
					this.isPostDialogVisible = false
				} else {
					if (!this.downForm.remarks) {
						return this.$message({
							message: '请填写下发信息！',
							type: 'warning'
						});
					}
					this.TipsInfo = false;
					this.isPostDialogVisible = false;
					this.taskDownVisible = false
					//请求任务下发接口
					console.log(this.downForm)
					this.distribData();
				}
			},
			async queryPage() {
				let data = {
					pageSize: this.pageSize,
					pageNum: this.pageNum
				};

				try {
					this.loading = true;
					const result = await
					selectPrintProduce(data);
					this.loading = false;
					const {
						total,
						list
					} = result.data;
					this.tableData = list;
					this.total = total;
				} catch (error) {
					this.loading = false;
				}
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
				this.ruleForm.coverImg = "";
			},

			/**@method 模态框打开时的回调 */
			openDialog() {

			},
			/**@method 模态框关闭的回调 */
			close() {
				(this.ruleForm = {
					lotNumber: '', //任务名称
					packBatchId: '', // 产品批次号
					boxBatchId: '', // 箱标批次号
				}),
				(this.fileList = []);
				this.bEdit = false;
				this.diagTitle = '添加任务'
			},
			setValue(e) {
				const {
					item: {
						value
					},
				} = e;
				this.ruleForm.address = `${value.city}${value.district}${value.business}`;
			},
			/**@method 编辑
			 * @param {Object} val - 当前点击行的值
			 */
			edit(val, b) {
				let data = JSON.parse(JSON.stringify(val));
				// this.getDevice()
				this.centerDialogVisible = true;
				// 回显数据
				this.ruleForm = data
				// this.ruleForm.id = data.id
				// this.ruleForm.name = data.name
				// this.ruleForm.classId = data.classId
				// this.ruleForm.introduce = data.introduce
				// this.ruleForm.ratio = data.ratio
				this.diagTitle = '编辑产品信息'
				this.editUpdate = true
				console.log(this.ruleForm)
			},
			/**@method 添加任务 */
			determine() {
				console.log(this.ruleForm)
				this.$refs.ruleForm.validate(async (valid) => {
					if (valid) {
						try {
							let params = {}
							params = this.ruleForm;
							if (this.editUpdate) {
								params.id = this.ruleForm.id
							}
							await addPrintProduce(params);
							this.$message({
								message: "操作成功",
								type: "success"

							});
							this.queryPage();
							this.centerDialogVisible = false;

						} catch (error) {
							this.$message({
								message: error.message,
								type: "error",
							});
						}
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			/**@method 删除
			 * @param {String} val - 文章id
			 */
			async del(val) {
				try {
					await delPrintProduce({
						id: val
					});
					this.$message({
						message: "删除成功",
						type: "success",
					});
					await this.queryPage();
					// await this.getPackBatch();
					// await this.getBoxBatch();
					// await this.getProductBatch();
				} catch (error) {
					console.log(error);
				}
			},
			handleSizeChange(num) {
				this.pageSize = num
				this.pageNum = 1
				this.queryPage()
			},
			handleCurrentChange(num) {
				this.pageNum = num
				this.queryPage()
			},
			ratioChange(val) {
				this.ruleForm.packRatio = val
			},
			// async getDevice(){
			//     const param = {type:3}
			//   getNopageDevice(param).then(response=>{
			//     this.deviceList = response.data
			//   })
			// },
			openAdd() {
				this.centerDialogVisible = true;
				this.editUpdate = false;
				// this.getLotNumber()
				// this.getDevice()
			}
		},
	};
</script>

<style lang="scss" scoped>
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 78px;
		height: 78px;
		line-height: 180px;
		text-align: center;
	}

	/deep/ .avatar-coverImg .el-upload--text {
		width: 100px;
		height: 100px;
	}

	/deep/ .avatar-coverImg .avatar-uploader-icon {
		line-height: 100px;
	}

	.select-goods-list {
		margin-bottom: 20px;
		border-radius: 40px;
		box-sizing: border-box;
		padding: 0 20px;
		background: #ffffff;
		transition: all 0.4s;
	}

	.select-goods-list:hover {
		background: #f5f5f6;
		transition: all 0.4s;
		cursor: pointer;
	}


	/deep/ .disabled .el-upload--picture-card {
		display: none;
	}

	.info {
		cursor: pointer;
		color: #113DEE;
	}

	/deep/ .disabled .el-upload--picture-card {
		display: none;
	}

	.info {
		cursor: pointer;
		color: blue;
	}

	/deep/ .disabled .el-upload--picture-card {
		display: none;
	}


	/deep/ .details .el-dialog__body {
		text-align: center;
		font-size: 16px
	}

	/deep/ .details .el-dialog__body div {
		line-height: 32px;
	}

	/deep/ .details .el-dialog__body div span:first-child {
		width: 180px;
		display: inline-block;
		text-align: right;
	}

	/deep/ .details .el-dialog__body div span:last-child {
		width: 180px;
		display: inline-block;
		text-align: left;
	}

	.tip {
		color: #f00;
		padding-left: 20px;
	}
</style>
